import {} from 'react';
import {
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
} from '@chakra-ui/react';
import { clsx } from 'clsx';

import { ROUTE } from '@/constants/route';

import { AnnoucementIcon } from './images/AnnoucementIcon';
import { KeyVisual } from './images/KeyVisual';
import { LpButtonLink } from '../LpButtonLink';

export const NewFeatureModelessCard = () => {
  return (
    <Accordion
      allowToggle
      defaultIndex={0}
      className={clsx(
        'tw-hidden lg:tw-block tw-fixed tw-bottom-0 tw-right-8 tw-z-popover'
      )}
    >
      <AccordionItem border="none">
        {({ isExpanded }) => (
          <div
            className={clsx(
              'tw-shadow-[0_12px_24px_0_#00000029] tw-w-80 tw-transform tw-duration-200 tw-mb-0 tw-overflow-hidden tw-rounded-t-md',
              isExpanded && 'tw-mb-8 tw-rounded-b-md'
            )}
          >
            <AccordionButton
              _hover={{ background: '#425F58' }}
              pr={3}
              className={clsx(
                'tw-bg-[#425F58] tw-h-12 tw-text-white tw-flex tw-justify-between'
              )}
            >
              <div className={clsx('tw-flex tw-items-center')}>
                <AnnoucementIcon />
                <span className={clsx('tw-ml-1 tw-text-sm tw-font-bold')}>
                  機能アップデート
                </span>
              </div>
              <div
                className={clsx('tw-flex tw-items-center')}
                style={{ transform: 'rotate(180deg)' }}
              >
                <AccordionIcon fontSize={28} />
              </div>
            </AccordionButton>
            <AccordionPanel p={0} className={clsx('tw-bg-white')}>
              <KeyVisual />
              <div className={clsx('tw-p-6 tw-text-[#425F58]')}>
                <h2
                  className={clsx(
                    'tw-text-[18px] tw-leading-[1.5] tw-font-bold'
                  )}
                >
                  団体応募とマイページ機能が追加されました
                </h2>
                <p className={clsx('tw-mt-2')}>
                  学校単位で応募を受け付ける場合、教職員・事務局のとりまとめ業務を軽減する「団体応募システム」をリリースしました。
                </p>
                <div className={clsx('tw-mt-4 tw-w-full tw-text-center')}>
                  <LpButtonLink href={ROUTE.contactCorporate()} isExternal>
                    お問い合わせはこちら
                  </LpButtonLink>
                </div>
              </div>
            </AccordionPanel>
          </div>
        )}
      </AccordionItem>
    </Accordion>
  );
};
