export const KeyVisual = () => {
  return (
    <svg
      width="320"
      height="180"
      viewBox="0 0 320 180"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clip-path="url(#clip0_5505_52655)">
        <rect width="320" height="180" fill="#F7FDF1" />
        <path
          d="M230.157 18H410.84C414.833 18 418.067 20.9651 418.067 24.6259V150.519H222.93V24.6259C222.93 20.9651 226.164 18 230.157 18Z"
          fill="#EAEDED"
          stroke="#425F58"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M433.415 150.519H207.585C205.605 150.519 204 151.388 204 152.46V160.227C204 161.299 205.605 162.169 207.585 162.169H433.415C435.395 162.169 437 161.299 437 160.227V152.46C437 151.388 435.395 150.519 433.415 150.519Z"
          fill="#EAEDED"
          stroke="#425F58"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <rect
          x="229.484"
          y="26.7375"
          width="182.031"
          height="114.316"
          fill="white"
          stroke="#425F58"
        />
        <g clip-path="url(#clip1_5505_52655)">
          <path
            d="M133.699 86.4624H74.543C74.543 62.994 83.2759 53.2303 96.5417 50.0528L104.124 57.5046L111.7 50.0528C124.966 53.2303 133.699 62.994 133.699 86.4624Z"
            fill="#425F58"
          />
          <path
            d="M104.546 47.6119C113.885 47.6119 121.456 40.1497 121.456 30.9448C121.456 21.7398 113.885 14.2776 104.546 14.2776C95.2074 14.2776 87.6367 21.7398 87.6367 30.9448C87.6367 40.1497 95.2074 47.6119 104.546 47.6119Z"
            fill="white"
          />
          <path
            d="M121.205 28.0683C117.294 27.9608 110.743 23.9661 109.729 19.7348C107.318 23.4607 102.387 26.1758 99.5228 26.7403C99.5228 26.7403 103.799 20.4929 102.043 21.6542C96.49 25.3425 91.1226 26.8963 88.0898 27.1059C89.8462 19.7509 96.55 14.2776 104.547 14.2776C112.543 14.2776 119.825 20.2348 121.205 28.0683Z"
            fill="#425F58"
            stroke="#425F58"
            stroke-miterlimit="10"
          />
          <path
            d="M104.129 57.6606L100.065 62.08L90.6719 52.2357C92.5101 51.3916 94.4738 50.7088 96.5466 50.2087L104.129 57.6606Z"
            fill="white"
          />
          <path
            d="M117.631 52.2626L108.183 62.08L104.125 57.6606L111.702 50.2087C113.796 50.7141 115.782 51.4077 117.631 52.2626Z"
            fill="white"
          />
          <path
            d="M133.699 86.4624H74.543C74.543 62.994 83.2759 53.2303 96.5417 50.0528L104.124 57.5046L111.7 50.0528C124.966 53.2303 133.699 62.994 133.699 86.4624Z"
            stroke="#425F58"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
          <path
            d="M104.546 47.6119C113.885 47.6119 121.456 40.1497 121.456 30.9448C121.456 21.7398 113.885 14.2776 104.546 14.2776C95.2074 14.2776 87.6367 21.7398 87.6367 30.9448C87.6367 40.1497 95.2074 47.6119 104.546 47.6119Z"
            stroke="#425F58"
            stroke-miterlimit="10"
          />
          <path
            d="M104.129 57.5046L100.066 61.9241L90.7109 52.0636"
            stroke="#425F58"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
          <path
            d="M104.125 57.5046L108.183 61.9241L117.538 52.0636"
            stroke="#425F58"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
          <path
            d="M88.0898 27.1221C91.1063 26.9393 96.4246 25.3855 102.043 21.6542C103.799 20.4875 99.5228 26.7404 99.5228 26.7404C102.392 26.1758 107.318 23.4607 109.729 19.7348C110.759 24.0306 117.316 28.0791 121.205 28.0791"
            stroke="#425F58"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
        </g>
        <path
          d="M203.221 53.1601L175.051 51.9239L180.035 45.9584C170.506 40.0756 165.263 39.8565 155.609 41.8327C150.453 42.8866 145.133 45.2759 140.975 47.949C145.883 37.3776 155.848 29.4087 168.241 27.5989C176.053 26.4584 182.745 28.8831 189.129 33.0228L192.675 25.9824L203.221 53.1601Z"
          fill="#15BC4A"
          stroke="#425F58"
          stroke-linejoin="round"
        />
        <path
          d="M16.5 85C16.5 81.9624 18.9624 79.5 22 79.5H186C189.038 79.5 191.5 81.9624 191.5 85V180.5H16.5V85Z"
          fill="#F7FFBE"
        />
        <path
          d="M16.5 85C16.5 81.9624 18.9624 79.5 22 79.5H186C189.038 79.5 191.5 81.9624 191.5 85V180.5H16.5V85Z"
          stroke="#425F58"
        />
        <g clip-path="url(#clip2_5505_52655)">
          <path
            d="M123.146 180.651H84.3555C84.3555 165.429 90.082 159.095 98.7809 157.034L103.753 161.868L108.721 157.034C117.42 159.095 123.146 165.429 123.146 180.651Z"
            fill="#EEE097"
          />
          <path
            d="M104.03 155.451C110.153 155.451 115.118 150.611 115.118 144.64C115.118 138.669 110.153 133.829 104.03 133.829C97.9058 133.829 92.9414 138.669 92.9414 144.64C92.9414 150.611 97.9058 155.451 104.03 155.451Z"
            fill="white"
          />
          <path
            d="M114.953 142.774C112.389 142.704 108.093 140.113 107.428 137.368C105.847 139.785 102.613 141.546 100.735 141.913C100.735 141.913 103.54 137.86 102.388 138.614C98.7466 141.006 95.227 142.014 93.2383 142.15C94.39 137.379 98.786 133.829 104.03 133.829C109.273 133.829 114.048 137.693 114.953 142.774Z"
            fill="#425F58"
            stroke="#425F58"
            stroke-miterlimit="10"
          />
          <path
            d="M103.758 161.969L101.093 164.836L94.9336 158.45C96.139 157.903 97.4267 157.46 98.7858 157.135L103.758 161.969Z"
            fill="white"
          />
          <path
            d="M112.61 158.468L106.415 164.836L103.754 161.969L108.722 157.135C110.096 157.463 111.398 157.913 112.61 158.468Z"
            fill="white"
          />
          <path
            d="M123.146 180.651H84.3555C84.3555 165.429 90.082 159.095 98.7809 157.034L103.753 161.868L108.721 157.034C117.42 159.095 123.146 165.429 123.146 180.651Z"
            stroke="#425F58"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
          <path
            d="M104.03 155.451C110.153 155.451 115.118 150.611 115.118 144.64C115.118 138.669 110.153 133.829 104.03 133.829C97.9058 133.829 92.9414 138.669 92.9414 144.64C92.9414 150.611 97.9058 155.451 104.03 155.451Z"
            stroke="#425F58"
            stroke-miterlimit="10"
          />
          <path
            d="M103.756 161.868L101.091 164.735L94.957 158.339"
            stroke="#425F58"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
          <path
            d="M103.754 161.868L106.415 164.735L112.549 158.339"
            stroke="#425F58"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
          <path
            d="M93.2383 142.16C95.2163 142.042 98.7037 141.034 102.388 138.614C103.54 137.857 100.735 141.913 100.735 141.913C102.617 141.546 105.847 139.785 107.428 137.369C108.104 140.155 112.403 142.781 114.953 142.781"
            stroke="#425F58"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
        </g>
        <g clip-path="url(#clip3_5505_52655)">
          <path
            d="M69.1463 180.651H30.3555C30.3555 165.429 36.082 159.095 44.7809 157.034L49.7527 161.868L54.7209 157.034C63.4198 159.095 69.1463 165.429 69.1463 180.651Z"
            fill="#EEE097"
          />
          <path
            d="M50.0296 155.451C56.1534 155.451 61.1178 150.611 61.1178 144.64C61.1178 138.669 56.1534 133.829 50.0296 133.829C43.9058 133.829 38.9414 138.669 38.9414 144.64C38.9414 150.611 43.9058 155.451 50.0296 155.451Z"
            fill="white"
          />
          <path
            d="M60.9533 142.774C58.3887 142.704 54.0929 140.113 53.4276 137.368C51.8466 139.785 48.6132 141.546 46.7353 141.913C46.7353 141.913 49.5396 137.86 48.3878 138.614C44.7466 141.006 41.227 142.014 39.2383 142.15C40.39 137.379 44.786 133.829 50.0296 133.829C55.2732 133.829 60.0483 137.693 60.9533 142.774Z"
            fill="#425F58"
            stroke="#425F58"
            stroke-miterlimit="10"
          />
          <path
            d="M49.7577 161.969L47.0929 164.836L40.9336 158.45C42.139 157.903 43.4267 157.46 44.7858 157.135L49.7577 161.969Z"
            fill="white"
          />
          <path
            d="M58.6102 158.468L52.4151 164.836L49.7539 161.969L54.7221 157.135C56.0956 157.463 57.3976 157.913 58.6102 158.468Z"
            fill="white"
          />
          <path
            d="M69.1463 180.651H30.3555C30.3555 165.429 36.082 159.095 44.7809 157.034L49.7527 161.868L54.7209 157.034C63.4198 159.095 69.1463 165.429 69.1463 180.651Z"
            stroke="#425F58"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
          <path
            d="M50.0296 155.451C56.1534 155.451 61.1178 150.611 61.1178 144.64C61.1178 138.669 56.1534 133.829 50.0296 133.829C43.9058 133.829 38.9414 138.669 38.9414 144.64C38.9414 150.611 43.9058 155.451 50.0296 155.451Z"
            stroke="#425F58"
            stroke-miterlimit="10"
          />
          <path
            d="M49.7561 161.868L47.0913 164.735L40.957 158.339"
            stroke="#425F58"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
          <path
            d="M49.7539 161.868L52.4151 164.735L58.5493 158.339"
            stroke="#425F58"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
          <path
            d="M39.2383 142.16C41.2163 142.042 44.7037 141.034 48.3878 138.614C49.5396 137.857 46.7353 141.913 46.7353 141.913C48.6168 141.546 51.8466 139.785 53.4276 137.369C54.1036 140.155 58.403 142.781 60.9533 142.781"
            stroke="#425F58"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
        </g>
        <g clip-path="url(#clip4_5505_52655)">
          <path
            d="M177.146 180.651H138.355C138.355 165.429 144.082 159.095 152.781 157.034L157.753 161.868L162.721 157.034C171.42 159.095 177.146 165.429 177.146 180.651Z"
            fill="#EEE097"
          />
          <path
            d="M158.03 155.451C164.153 155.451 169.118 150.611 169.118 144.64C169.118 138.669 164.153 133.829 158.03 133.829C151.906 133.829 146.941 138.669 146.941 144.64C146.941 150.611 151.906 155.451 158.03 155.451Z"
            fill="white"
          />
          <path
            d="M168.953 142.774C166.389 142.704 162.093 140.113 161.428 137.368C159.847 139.785 156.613 141.546 154.735 141.913C154.735 141.913 157.54 137.86 156.388 138.614C152.747 141.006 149.227 142.014 147.238 142.15C148.39 137.379 152.786 133.829 158.03 133.829C163.273 133.829 168.048 137.693 168.953 142.774Z"
            fill="#425F58"
            stroke="#425F58"
            stroke-miterlimit="10"
          />
          <path
            d="M157.758 161.969L155.093 164.836L148.934 158.45C150.139 157.903 151.427 157.46 152.786 157.135L157.758 161.969Z"
            fill="white"
          />
          <path
            d="M166.61 158.468L160.415 164.836L157.754 161.969L162.722 157.135C164.096 157.463 165.398 157.913 166.61 158.468Z"
            fill="white"
          />
          <path
            d="M177.146 180.651H138.355C138.355 165.429 144.082 159.095 152.781 157.034L157.753 161.868L162.721 157.034C171.42 159.095 177.146 165.429 177.146 180.651Z"
            stroke="#425F58"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
          <path
            d="M158.03 155.451C164.153 155.451 169.118 150.611 169.118 144.64C169.118 138.669 164.153 133.829 158.03 133.829C151.906 133.829 146.941 138.669 146.941 144.64C146.941 150.611 151.906 155.451 158.03 155.451Z"
            stroke="#425F58"
            stroke-miterlimit="10"
          />
          <path
            d="M157.756 161.868L155.091 164.735L148.957 158.339"
            stroke="#425F58"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
          <path
            d="M157.754 161.868L160.415 164.735L166.549 158.339"
            stroke="#425F58"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
          <path
            d="M147.238 142.16C149.216 142.042 152.704 141.034 156.388 138.614C157.54 137.857 154.735 141.913 154.735 141.913C156.617 141.546 159.847 139.785 161.428 137.369C162.104 140.155 166.403 142.781 168.953 142.781"
            stroke="#425F58"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
        </g>
        <rect
          x="92"
          y="96"
          width="10"
          height="10"
          stroke="#425F58"
          stroke-width="2"
        />
        <rect
          x="92"
          y="110"
          width="10"
          height="10"
          stroke="#425F58"
          stroke-width="2"
        />
        <rect
          x="106"
          y="96"
          width="10"
          height="10"
          stroke="#425F58"
          stroke-width="2"
        />
        <rect
          width="4"
          height="4"
          transform="translate(105 109)"
          fill="#425F58"
        />
        <rect
          width="4"
          height="4"
          transform="translate(113 109)"
          fill="#425F58"
        />
        <rect
          width="4"
          height="4"
          transform="translate(113 117)"
          fill="#425F58"
        />
        <rect
          width="4"
          height="4"
          transform="translate(105 117)"
          fill="#425F58"
        />
        <rect
          width="4"
          height="4"
          transform="translate(109 113)"
          fill="#425F58"
        />
        <rect
          x="38"
          y="96"
          width="10"
          height="10"
          stroke="#425F58"
          stroke-width="2"
        />
        <rect
          x="38"
          y="110"
          width="10"
          height="10"
          stroke="#425F58"
          stroke-width="2"
        />
        <rect
          x="52"
          y="96"
          width="10"
          height="10"
          stroke="#425F58"
          stroke-width="2"
        />
        <rect
          width="4"
          height="4"
          transform="translate(51 109)"
          fill="#425F58"
        />
        <rect
          width="4"
          height="4"
          transform="translate(59 109)"
          fill="#425F58"
        />
        <rect
          width="4"
          height="4"
          transform="translate(59 117)"
          fill="#425F58"
        />
        <rect
          width="4"
          height="4"
          transform="translate(51 117)"
          fill="#425F58"
        />
        <rect
          width="4"
          height="4"
          transform="translate(55 113)"
          fill="#425F58"
        />
        <rect
          x="146"
          y="96"
          width="10"
          height="10"
          stroke="#425F58"
          stroke-width="2"
        />
        <rect
          x="146"
          y="110"
          width="10"
          height="10"
          stroke="#425F58"
          stroke-width="2"
        />
        <rect
          x="160"
          y="96"
          width="10"
          height="10"
          stroke="#425F58"
          stroke-width="2"
        />
        <rect
          width="4"
          height="4"
          transform="translate(159 109)"
          fill="#425F58"
        />
        <rect
          width="4"
          height="4"
          transform="translate(167 109)"
          fill="#425F58"
        />
        <rect
          width="4"
          height="4"
          transform="translate(167 117)"
          fill="#425F58"
        />
        <rect
          width="4"
          height="4"
          transform="translate(159 117)"
          fill="#425F58"
        />
        <rect
          width="4"
          height="4"
          transform="translate(163 113)"
          fill="#425F58"
        />
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M22 13C18.6863 13 16 15.6863 16 19V37C16 40.3137 18.6863 43 22 43H51.8683L62.7066 50.8824L60.736 43H66C69.3137 43 72 40.3137 72 37V19C72 15.6863 69.3137 13 66 13H22Z"
          fill="white"
        />
        <path
          d="M51.8683 43L52.1624 42.5956C52.0769 42.5335 51.974 42.5 51.8683 42.5V43ZM62.7066 50.8824L62.4125 51.2867C62.5826 51.4104 62.8119 51.4145 62.9862 51.2969C63.1605 51.1793 63.2426 50.9651 63.1916 50.7611L62.7066 50.8824ZM60.736 43V42.5C60.582 42.5 60.4366 42.5709 60.3419 42.6923C60.2471 42.8137 60.2136 42.9719 60.2509 43.1213L60.736 43ZM16.5 19C16.5 15.9624 18.9624 13.5 22 13.5V12.5C18.4101 12.5 15.5 15.4101 15.5 19H16.5ZM16.5 37V19H15.5V37H16.5ZM22 42.5C18.9624 42.5 16.5 40.0376 16.5 37H15.5C15.5 40.5899 18.4101 43.5 22 43.5V42.5ZM51.8683 42.5H22V43.5H51.8683V42.5ZM51.5742 43.4044L62.4125 51.2867L63.0007 50.478L52.1624 42.5956L51.5742 43.4044ZM63.1916 50.7611L61.2211 42.8787L60.2509 43.1213L62.2215 51.0036L63.1916 50.7611ZM66 42.5H60.736V43.5H66V42.5ZM71.5 37C71.5 40.0376 69.0376 42.5 66 42.5V43.5C69.5899 43.5 72.5 40.5899 72.5 37H71.5ZM71.5 19V37H72.5V19H71.5ZM66 13.5C69.0376 13.5 71.5 15.9624 71.5 19H72.5C72.5 15.4101 69.5899 12.5 66 12.5V13.5ZM22 13.5H66V12.5H22V13.5Z"
          fill="#425F58"
        />
        <path
          d="M34.13 33.664H40.962V34.896H34.13V33.664ZM36.132 25.054H37.756V26.734H36.132V25.054ZM30.966 26.02H43.146V28.806H41.508V27.28H32.548V28.806H30.966V26.02ZM34.074 27.868H40.934V31.004H34.074V29.8H39.254V29.072H34.074V27.868ZM34.144 31.634H41.998V35.274H40.318V32.866H34.144V31.634ZM33.15 27.868H34.746V35.288H33.15V27.868ZM32.38 23.094H36.79V24.41H32.38V23.094ZM38.05 23.094H43.482V24.41H38.05V23.094ZM32.436 22.002L34.004 22.422C33.458 23.682 32.562 24.956 31.736 25.754C31.414 25.502 30.742 25.054 30.364 24.858C31.204 24.158 32.002 23.066 32.436 22.002ZM38.134 22.002L39.73 22.394C39.226 23.598 38.358 24.76 37.518 25.488C37.182 25.236 36.468 24.872 36.048 24.69C36.944 24.046 37.728 23.024 38.134 22.002ZM32.898 24.116L34.326 23.738C34.676 24.214 35.068 24.886 35.236 25.334L33.724 25.768C33.598 25.334 33.234 24.62 32.898 24.116ZM39.156 24.158L40.626 23.766C41.032 24.228 41.522 24.9 41.746 25.348L40.192 25.782C40.01 25.362 39.548 24.648 39.156 24.158ZM51.182 26.622V27.826H55.424V26.622H51.182ZM51.182 24.13V25.306H55.424V24.13H51.182ZM49.67 22.702H57.02V29.24H49.67V22.702ZM49.572 30.444H57.174V31.956H49.572V30.444ZM48.592 33.3H57.636V34.812H48.592V33.3ZM44.49 22.912H49.138V24.48H44.49V22.912ZM44.63 26.944H48.9V28.498H44.63V26.944ZM44.336 32.264C45.54 31.956 47.332 31.396 49.012 30.85L49.306 32.46C47.738 32.992 46.058 33.538 44.714 33.972L44.336 32.264ZM46.044 23.528H47.64V32.138L46.044 32.39V23.528ZM52.624 23.304H54.038V28.582H54.192V34.014H52.47V28.582H52.624V23.304Z"
          fill="#425F58"
        />
      </g>
      <defs>
        <clipPath id="clip0_5505_52655">
          <rect width="320" height="180" fill="white" />
        </clipPath>
        <clipPath id="clip1_5505_52655">
          <rect
            width="61"
            height="74"
            fill="white"
            transform="translate(74 13)"
          />
        </clipPath>
        <clipPath id="clip2_5505_52655">
          <rect
            width="40"
            height="48"
            fill="white"
            transform="translate(84 133)"
          />
        </clipPath>
        <clipPath id="clip3_5505_52655">
          <rect
            width="40"
            height="48"
            fill="white"
            transform="translate(30 133)"
          />
        </clipPath>
        <clipPath id="clip4_5505_52655">
          <rect
            width="40"
            height="48"
            fill="white"
            transform="translate(138 133)"
          />
        </clipPath>
      </defs>
    </svg>
  );
};
