export const AnnoucementIcon = () => {
  return (
    <svg
      width="22"
      height="16"
      viewBox="0 0 22 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M17.2369 8.84997V7.10002H21.1022V8.84997H17.2369ZM18.4292 15.8307L15.3369 13.5615L16.3984 12.1154L19.4907 14.4346L18.4292 15.8307ZM16.3599 3.84615L15.2984 2.40002L18.3907 0.130798L19.4522 1.52692L16.3599 3.84615ZM3.62536 14.8307V10.8269H2.85611C2.32408 10.8269 1.8648 10.6336 1.47826 10.2471C1.09171 9.86055 0.898438 9.40126 0.898438 8.86922V7.13077C0.898438 6.59874 1.0917 6.13945 1.47824 5.7529C1.86479 5.36637 2.32408 5.1731 2.85611 5.1731H6.22149L11.0022 2.30005V13.6999L6.22149 10.8269H5.37531V14.8307H3.62536ZM13.3869 11.2692V4.73077C13.8125 5.11924 14.1535 5.59584 14.4099 6.16057C14.6664 6.72532 14.7946 7.33847 14.7946 8C14.7946 8.66153 14.6664 9.27467 14.4099 9.83942C14.1535 10.4042 13.8125 10.8808 13.3869 11.2692Z"
        fill="white"
      />
    </svg>
  );
};
