import type { InputOption } from '@/types/ui';

import {
  APPLICANT_FIELD_LABEL,
  APPLICANT_GROUP_FIELD_LABEL,
} from '@/constants/features/applicant';

export const SCREENING_METHOD = '審査方式';

export const SCREENING_METHOD_TYPE = {
  scoring: 'scoring',
  voting: 'voting',
} as const;

export const SCREENING_METHOD_TYPE_NAME: Record<
  keyof typeof SCREENING_METHOD_TYPE,
  string
> = {
  scoring: '採点方式',
  voting: '投票方式',
};

export const VOTING_VALUE = {
  positive: 1,
  negative: 0,
};

export const VOTING_LABEL = {
  positive: '○',
  negative: '×',
};

/** 投票方式の選択肢 */
export const VOTING_OPTIONS: InputOption[] = [
  { label: VOTING_LABEL.positive, value: VOTING_VALUE.positive },
  { label: VOTING_LABEL.negative, value: VOTING_VALUE.negative },
];

export const APPLICANT_FIELD_VISIBILITY_LABEL = {
  nickname: APPLICANT_FIELD_LABEL.nickname,
  ageAtEntry: APPLICANT_FIELD_LABEL.ageAtEntry,
  gender: APPLICANT_FIELD_LABEL.gender,
  prefecture: 'お住まいの都道府県',
  job: APPLICANT_FIELD_LABEL.job,
  groupName: APPLICANT_GROUP_FIELD_LABEL.groupName,
  gradeClass: APPLICANT_GROUP_FIELD_LABEL.gradeClass,
};

export const SCREENING_SET_UP_STEP = {
  method: SCREENING_METHOD,
  work: '審査作品登録',
  juror: '審査員登録',
  assignment: '作品割振り',
} as const;

export const SCREENING_FIELD_LABEL = {
  screening: '審査',
  passed: '通過',
  stickyNote: '付箋',
  voting: '投票',
  scoring: '採点',
  notVoted: '未投票',
  notScored: '未採点',
  averageScore: '平均点',
  totalPositiveVotes: `${VOTING_LABEL.positive}の合計`,
  totalNegativeVotes: `${VOTING_LABEL.negative}の合計`,
  juror: '審査員',
};
