import { COMMON_LABEL } from '@/constants/label';

export const SLUG_REGEX = /^[A-Za-z0-9-]+$/;

export const APPLICANT_FIELD_KEYS = [
  'email',
  'nickname',
  'name',
  'nameKana',
  'birth',
  'ageAtEntry',
  'gender',
  'job',
  'phoneNumber',
  'address',
  'prefecture',
] as const;

export const APPLICANT_FIELD_KEYS_FOR_JUROR = [
  'nickname',
  'ageAtEntry',
  'gender',
  'job',
  'prefecture',
] as const satisfies readonly (typeof APPLICANT_FIELD_KEYS)[number][];

export const APPLICANT_FIELD_LABEL: Record<
  (typeof APPLICANT_FIELD_KEYS)[number],
  string
> = {
  email: COMMON_LABEL.email,
  nickname: 'ペンネーム',
  name: 'お名前',
  nameKana: 'お名前（カナ）',
  birth: '生年月日',
  ageAtEntry: '応募時の年齢',
  gender: '性別',
  job: '職業',
  phoneNumber: COMMON_LABEL.phoneNumber,
  address: COMMON_LABEL.address,
  prefecture: COMMON_LABEL.prefecture,
};

export const APPLICANT_GROUP_FIELD_KEYS = [
  'groupName',
  'gradeClass',
  'representativeName',
  'representativeKana',
  'groupPhoneNumber',
  'groupAddress',
  'groupPrefecture',
] as const;

export const APPLICANT_GROUP_FIELD_KEYS_FOR_JUROR = [
  'groupName',
  'gradeClass',
] as const satisfies readonly (typeof APPLICANT_GROUP_FIELD_KEYS)[number][];

export const APPLICANT_GROUP_FIELD_LABEL: Record<
  (typeof APPLICANT_GROUP_FIELD_KEYS)[number],
  string
> = {
  groupName: '学校・団体名',
  gradeClass: '学年・クラス',
  representativeName: '代表者氏名',
  representativeKana: '代表者フリガナ',
  groupPhoneNumber: `学校・団体の${COMMON_LABEL.phoneNumber}`,
  groupAddress: `学校・団体の${COMMON_LABEL.address}`,
  groupPrefecture: `学校・団体の${COMMON_LABEL.prefecture}`,
};
