import { clsx } from 'clsx';
import { useRouter } from 'next/router';
import { NextSeo } from 'next-seo';
import { useEffect, useMemo, useRef } from 'react';

import type { RefObject } from 'react';

import {
  CaseStudyCard,
  Container,
  LpGlobalHeader,
  PlanSummaryTable,
  FeatureList,
  CtaSignup,
  FunctionPanel,
  UsingImageArea,
  LpGlobalFooter,
  PublishingContestCard,
  PublishingContestSlider,
  LpSection,
  PlanDetailModal,
  MainVisual,
  LogoList,
} from '@/components/features/lp';
import { NavHrefs, NAVS } from '@/components/features/lp/LpGlobalNav/const';
import { NewFeatureModelessCard } from '@/components/features/lp/NewFeatureModelessCard';
import { LoadingOverlay, Tag, TextLink } from '@/components/ui';
import { APP_META } from '@/constants/app';
import { ROUTE } from '@/constants/route';
import { SITE } from '@/constants/site';
import { TopPageDocument } from '@/graphql/generated';
import { useUser } from '@/hooks/features/auth';
import { useQueryWrapper } from '@/libs/apollo';

import CaseStudyJson from './caseStudy.json';

export const TopPage = () => {
  const router = useRouter();

  const featureRef = useRef<HTMLDivElement>(null);
  const usingImageRef = useRef<HTMLDivElement>(null);
  const planRef = useRef<HTMLDivElement>(null);

  const { data } = useQueryWrapper(TopPageDocument, {
    variables: {
      limit: 10,
    },
    fetchPolicy: 'network-only',
  });

  // ナビゲーションにrefを紐付ける
  const navs = useMemo(() => {
    const contentRefs: Record<string, RefObject<HTMLDivElement>> = {
      [NavHrefs.feature]: featureRef,
      [NavHrefs.usingImage]: usingImageRef,
      [NavHrefs.plan]: planRef,
    };

    return NAVS.map((NAV) => {
      const ref = contentRefs[NAV.hash] ?? undefined;

      return {
        ...NAV,
        ref,
      };
    });
  }, []);

  const {
    isLoggedIn,
    isJuror,
    isKgAdmin,
    isKgInformer,
    isOrgAdmin,
    isLoading,
  } = useUser();

  useEffect(() => {
    if (isLoading) return;

    // NOTE: hashの対象セクションまでのスクロール
    if (router.asPath !== router.route) {
      const { hash } = new URL(router.asPath, window.location.origin);
      if (!hash) return;

      const element = document.getElementById(hash.replace('#', ''));
      if (element) {
        element.scrollIntoView();
      }
    }
  }, [isLoading, router.asPath, router.route]);

  if (isLoading) return <LoadingOverlay isLoading />;

  if (isKgAdmin || isKgInformer || isOrgAdmin) {
    router.replace(ROUTE.contestList());
    return <LoadingOverlay isLoading />;
  } else if (isJuror) {
    router.replace(ROUTE.assignedScreeningList());
    return <LoadingOverlay isLoading />;
  } else if (isLoggedIn) {
    router.replace(ROUTE.organization());
  }

  const title = `${APP_META.TITLE} | 募集から審査までワンストップでオンライン完結`;
  const description = `公募・コンテスト運営実績No.1「${APP_META.TITLE}」即日スピード掲載OK！省庁や自治体での活用も多数！`;
  const url = APP_META.DOMAIN;
  const thumbnailUrl = `${APP_META.DOMAIN}/ogp.png`;

  return (
    <>
      <NextSeo
        title={title}
        description={description}
        canonical={url}
        openGraph={{
          url,
          title,
          description,
          images: [
            {
              url: thumbnailUrl,
              width: 1200,
              height: 630,
              alt: APP_META.TITLE,
            },
          ],
        }}
        twitter={{
          site: SITE.TWITTER.SITE,
          handle: SITE.TWITTER.DOMAIN,
          cardType: 'summary',
        }}
      />

      <main>
        <LpGlobalHeader navs={navs} />
        <NewFeatureModelessCard />
        <MainVisual />

        <LpSection>
          <Container>
            <header>
              <h2
                className={clsx(
                  'tw-text-center tw-text-2xl md:tw-text-4xl tw-font-medium md:tw-leading-[1.6]'
                )}
              >
                公募・コンテスト運営実績No.1
                <br className="tw-block lg:tw-hidden" />「{APP_META.TITLE}」
                <br />
                即日スピード掲載OK！
                <br className="tw-block lg:tw-hidden" />
                省庁や自治体での活用も多数！
              </h2>
              <p className="tw-mt-4 md:tw-mt-6 tw-text-center">
                <small className="tw-text-sm md:tw-text-base">
                  ※旧システム「あつま～る」を含む
                </small>
              </p>
            </header>

            <div className="tw-mt-12 md:tw-mt-16">
              <div className="tw-bg-lp-bgYellow tw-pt-2 md:tw-pt-[10px] tw-pb-2 md:tw-pb-[10px]">
                <h3 className="tw-text-center tw-text-base md:tw-text-xl tw-font-bold">
                  {APP_META.TITLE}が選ばれる理由
                </h3>
              </div>
              <div className="tw-mt-4 md:tw-mt-6">
                <FeatureList />
              </div>
            </div>
          </Container>
        </LpSection>
        <section>
          <CtaSignup />
        </section>
        <LpSection id={NavHrefs.feature} contentRef={featureRef}>
          <Container>
            <header>
              <h2
                className={clsx(
                  'tw-text-center tw-text-2xl md:tw-text-4xl tw-font-medium'
                )}
              >
                {APP_META.TITLE}の
                <br className="tw-block md:tw-hidden" />
                5つの基本機能
              </h2>
            </header>
            <div
              className={clsx(
                'tw-flex tw-flex-col tw-gap-y-10 md:tw-gap-y-20',
                'tw-mt-12 md:tw-mt-[78px]'
              )}
            >
              <FunctionPanel
                functionNumber={1}
                title="募集ページの作成・公開"
                thumbnail="./images/lp/feature-1.jpg"
                description={
                  <>
                    必要な情報を入力するだけで、誰でもすぐにコンテストの募集ページを作成できます。
                    ページは
                    <TextLink
                      color={'lp.green.400'}
                      textDecoration="underline"
                      href={ROUTE.koubo()}
                      isExternal
                    >
                      Koubo
                    </TextLink>
                    上に公開され、応募数向上も見込めます。
                    募集ページは同時にいくつでも作成可能。基本無料です。
                  </>
                }
                priceTag={
                  <Tag backgroundColor={'#6EB7D1'} color="white">
                    無料
                  </Tag>
                }
              />

              <FunctionPanel
                reverse
                functionNumber={2}
                title="応募フォームの作成"
                thumbnail="./images/lp/feature-2.jpg"
                description={
                  '作品の応募を受け付けるフォームを作成できます。\n専門的な知識がなくても、簡単に項目をカスタマイズ。\nジャンルに応じて、テキスト入力やファイル添付などさまざまな応募方法が選べます。'
                }
                priceTag={
                  <Tag backgroundColor={'#78C197'} color="white">
                    一部有料
                  </Tag>
                }
              />

              <FunctionPanel
                functionNumber={3}
                title="作品の管理"
                thumbnail="./images/lp/feature-3.jpg"
                description={`応募された作品は${APP_META.TITLE}ですべて管理。\n応募作品の一括ダウンロードも可能です。\n応募者の個人情報も管理・削除できるため、セキュリティ面も安心です。`}
                priceTag={
                  <Tag backgroundColor={'#78C197'} color="white">
                    一部有料
                  </Tag>
                }
              />

              <FunctionPanel
                reverse
                functionNumber={4}
                title="出品料の管理"
                thumbnail="./images/lp/feature-4.jpg"
                description={`${APP_META.TITLE}では出品料や投句料の徴収にも対応しています。\n出品料・投句料は、応募者に前払いでお支払いいただきます。\n前払いなので、徴収漏れの心配もありません。`}
                priceTag={
                  <Tag backgroundColor={'#EB7144'} color="white">
                    有料
                  </Tag>
                }
              />

              <FunctionPanel
                functionNumber={5}
                title="オンライン審査対応"
                thumbnail="./images/lp/feature-5.jpg"
                description={
                  '審査員を招待して、オンライン上で作品の審査を行うことができます。\n採点方式・投票方式などの審査方式を選択できるほか、審査担当作品の割り振りや、審査に利用する項目の選択など、柔軟な設定が可能です。'
                }
                priceTag={
                  <Tag backgroundColor={'#EB7144'} color="white">
                    有料
                  </Tag>
                }
              />
            </div>
          </Container>
        </LpSection>
        <section>
          <CtaSignup />
        </section>
        <LpSection
          className=" tw-bg-lp-bgGreen"
          id={NavHrefs.usingImage}
          contentRef={usingImageRef}
        >
          <Container>
            <header>
              <h2
                className={clsx(
                  'tw-text-center tw-text-2xl md:tw-text-4xl tw-font-medium'
                )}
              >
                ご利用のイメージ
              </h2>
            </header>
            <div className="tw-mt-10">
              <UsingImageArea />
            </div>
          </Container>
        </LpSection>
        <section>
          <CtaSignup />
        </section>
        <LpSection id={NavHrefs.plan} contentRef={planRef}>
          <Container>
            <header>
              <h2
                className={clsx(
                  'tw-text-center tw-text-2xl md:tw-text-4xl tw-font-medium'
                )}
              >
                プラン比較
              </h2>
              <p className="tw-mt-4 md:tw-mt-6 tw-text-sm md:tw-text-base tw-text-center">
                <small>※下記の表に記載している機能は一部です</small>
              </p>
            </header>

            <div className="tw-mt-5 md:tw-mt-16">
              <PlanSummaryTable />
            </div>

            <footer className="tw-mt-11">
              <PlanDetailModal />
            </footer>
          </Container>
        </LpSection>
        <section>
          <CtaSignup />
        </section>
        <LpSection className="tw-bg-lp-bgGreen">
          <Container>
            <header>
              <h2
                className={clsx(
                  'tw-text-center tw-text-2xl md:tw-text-4xl tw-font-medium'
                )}
              >
                ご利用企業・団体さま
              </h2>
              <p className="tw-mt-4 md:tw-mt-6 tw-text-sm md:tw-text-base tw-text-center">
                <small>※『あつま〜る』のご利用実績を含む</small>
              </p>
            </header>

            <div className="tw-mt-10 md:tw-mt-16">
              {/* 活用事例 */}
              <div className="tw-flex tw-flex-wrap tw-gap-y-4 md:tw-gap-[3.5%]">
                {CaseStudyJson.caseStudy.map((item) => {
                  return (
                    <CaseStudyCard
                      className="tw-w-full md:tw-w-[31%]"
                      key={item.title}
                      {...item}
                    />
                  );
                })}
              </div>

              {/* 企業バナー */}
              <LogoList />
            </div>
          </Container>
        </LpSection>
        <LpSection>
          <Container>
            <header>
              <h2
                className={clsx(
                  'tw-text-center tw-text-2xl md:tw-text-4xl tw-font-medium'
                )}
              >
                公開中のコンテスト
              </h2>
            </header>

            {data && (
              <div className="tw-mt-10 md:tw-mt-16">
                <PublishingContestSlider
                  contests={data.contests.map((contest, index) => (
                    <PublishingContestCard contest={contest} key={index} />
                  ))}
                  arrowPosY={'-3em'}
                  view={4}
                  spView={1}
                  className="child:!tw-pr-[16%] md:child:!tw-pr-0"
                />
              </div>
            )}
          </Container>
        </LpSection>

        <section>
          <CtaSignup />
        </section>
      </main>
      <LpGlobalFooter />
    </>
  );
};
